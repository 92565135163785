$(document).foundation();

$(document).ready(function () {
    // utils
    initVerticalCenter();
    initAnimateInView();
    inlineSVGs([
	    
    ]);

    // global
	initHeader();
	initForms();
	initSliders();
	hideFiltersIfSingleCategory()
	initAccordion();
	
	// template specific
	initImagePanel();
	initGrid();
	initListing();
});





function initVerticalCenter() {
    $('.vertical-center').each(function () {
        $(this).wrapInner('<div class="vertical-center-table"><div class="vertical-center-row"><div class="vertical-center-cell"></div></div></div>');
    });
}



// https://stackoverflow.com/questions/24933430/img-src-svg-changing-the-fill-color
function inlineSVGs(inputValue) {
    var selectorString = '';
    if (typeof inputValue === "object") {
        selectorString = inputValue.join(',');
    } else {
        selectorString = inputValue;
    }

    $(selectorString).each(function () {
        var $img = $(this);
        var imgID = $img.attr('id');
        var imgClass = $img.attr('class');
        var imgURL = $img.attr('src');
        
        if (imgURL.indexOf('.svg') > -1) {
	        $.get(imgURL, function (data) {
	            // Get the SVG tag, ignore the rest
	            var $svg = $(data).find('svg');
	
	            // Add replaced image's ID to the new SVG
	            if (typeof imgID !== 'undefined') {
	                $svg = $svg.attr('id', imgID);
	            }
	            // Add replaced image's classes to the new SVG
	            if (typeof imgClass !== 'undefined') {
	                $svg = $svg.attr('class', imgClass + ' replaced-svg');
	            }
	
	            // Remove any invalid XML tags as per http://validator.w3.org
	            $svg = $svg.removeAttr('xmlns:a');
	
	            // Check if the viewport is set, if the viewport is not set the SVG wont't scale.
	            if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
	                $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
	            }
	
	            // Replace image with new SVG
	            $img.replaceWith($svg);
	        }, 'xml');
        }
    });
}



function pad(n, width, z) {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}









/**
 * Scales any element to simulate the CSS background-size: cover property.
 * @param {DOMelement} element The element to scale
 * @param {bool} [scaleToParent] Scale the element to fill the parent instead
 *                               of the whole browser window.
 */
function coverViewport(element, scaleToParent) {
    if (typeof scaleToParent === 'undefined') { scaleToParent = false; }

    var viewportWidth, viewportHeight;
    if (scaleToParent) {
        viewportWidth = $(element).parent().innerWidth();
        viewportHeight = $(element).parent().innerHeight();
    } else {
        viewportWidth = $(window).innerWidth();
        viewportHeight = $(window).innerHeight();
    }

    var $element = $(element);

    var elementWidth = $element.attr('width');
    var elementHeight = $element.attr('height');

    // Aspect ratio = height / width
    var viewportRatio = viewportHeight / viewportWidth;
    var videoRatio = elementHeight / elementWidth;

    //console.log('Viewport: ', viewportRatio, ' Video: ', videoRatio);


    var newWidth = (elementWidth / elementHeight) * viewportHeight
    var newHeight = (elementHeight / elementWidth) * viewportWidth;
    var newTop = 0;

    if (viewportRatio > videoRatio) {
        // set video height to the viewport, and extend the video beyond the viewport width, i.e. portrait viewport
        //console.log('crop left and right');
        $element.css('height', viewportHeight);
        $element.css('width', newWidth);
        $element.css('left', '-' + (newWidth - viewportWidth) / 2 + 'px');
        $element.css('top', '0');
    } else if (viewportRatio < videoRatio) {
        // set video width to the viewport, and extend the video beyond the viewport height, i.e. landscape viewport
        //console.log('crop top and bottom');
        $element.css('width', viewportWidth);
        $element.css('height', newHeight);
        $element.css('left', '0');
        $element.css('top', '-' + (newHeight - viewportHeight) / 2 + 'px');
    } else {
        // set the video width AND height to the viewport size, i.e. viewport is the same ratio as the video
        //console.log('no crop');
        $element.css('height', viewportHeight);
        $element.css('width', viewportWidth);
        $element.css('left', '0');
        $element.css('top', '0');
    }
}








function initAnimateInView() {
	$(window).on('DOMContentLoaded load resize scroll', animateElementsInView);
	animateElementsInView();
}

function animateElementsInView() {
	$('[data-animate-in-view]').each(function () {
		//console.log($(this));
		if ( isElementInViewport($(this)) ) {
			$(this).addClass( $(this).data('animate-in-view') );
		}
	});
}

// https://stackoverflow.com/questions/123999/how-to-tell-if-a-dom-element-is-visible-in-the-current-viewport
function isElementInViewport(el) {
	if (typeof jQuery === "function" && el instanceof jQuery) {
        el = el[0];
    }
    
    var rect = el.getBoundingClientRect();

    return rect.bottom > 0 &&
        rect.right > 0 &&
        rect.left < (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */ &&
        rect.top < (window.innerHeight || document.documentElement.clientHeight) /* or $(window).height() */;
}





function initImagePanel() {
	if ($('.image-panel').length > 0) {
		scaleImagePanel();
		$(window).on('resize', scaleImagePanel);
	}
}

function scaleImagePanel() {
	var contentHeight = $('.image-panel__content').outerHeight();
	var imageHeight = $('.image-panel').outerHeight();
	
	if (contentHeight > imageHeight) {
		$('.image-panel').css('height', (contentHeight + 85) + 'px');
	}
}



function initHeader() {
	setHeaderScrolledClass();
	$(window).on('scroll', setHeaderScrolledClass);
	
	if ( $('.video, .slider__image').length === 0 ) {
		$('main').addClass('no-slider');
	}
}

function setHeaderScrolledClass() {
	if (window.pageYOffset > 50) {
		$('.header').addClass('has-scrolled');
	} else {
		$('.header').removeClass('has-scrolled');
	}
}




function initForms() {
	$('.validate').validate();
}




function initSliders() {
	$('.slider__inner').reformSlider({
		speed: 4000,
		animationSpeed: 1000,
		arrows: false,
		pagination: true,
		paginationArrows: false
	});
	
	if ( $('.slider__slide iframe').length > 0 ) {
		coverViewport('.slider__slide iframe');
		$(window).on('resize', function () {
			coverViewport('.slider__slide iframe');
		});
	}

	$('.news-featured__slider').slick({
		arrows: false,
		dots: true,
		slidesToShow: 1,
		slidesToScroll: 1
	})
}


function initGrid() {
	$('.grid__cta button').on('click', function (e) {
		e.preventDefault();
		
		// Click an already open item, so close it and then do nothing else
		if ( $(this).parents('.grid__item').hasClass('open') ) {
			$('.grid__item.open').removeClass('open');
			$('.grid__expanded-content').slideUp(function () {
				$('.grid__expanded-content').remove();
			});
			
			$(this).text( $(this).data('open-text') );
			return;
		}
		
		$(this).text( $(this).data('close-text') );
		
		var $alreadyOpenItem = $('.grid__item.open');
		var $alreadyOpenContent = $('.grid__expanded-content');
		var $clickedItem = $(this).parents('.grid__item');
		
		$alreadyOpenItem.removeClass('open');
		$alreadyOpenContent.slideUp(function () {
			$alreadyOpenContent.remove();
		});
		
		$clickedItem.toggleClass('open');
		
		if ( $clickedItem.hasClass('open') ) {
			// Find the rightmost grid item to insert the content after
			var $insertAfter = $clickedItem;
			var offsetY = $clickedItem.offset().top;
			
			$clickedItem.nextAll('.grid__item').each(function () {
				if ( $(this).offset().top > offsetY ) {
					// found where the next row starts so this is where the content should be added before
					return false;
				}
				
				$insertAfter = $(this);
			});
			
			$insertAfter.after( '<div class="grid__expanded-content cell small-12">' + $clickedItem.find('.grid__content').html() + '</div>' );
			$('.grid__expanded-content').slideDown();
			
			// now make sure the content is within the users viewport
			if ( !isElementInViewport($('.grid__expanded-content')) ) {
				$clickedItem
				$('html, body').animate({
					scrollTop: $clickedItem.offset().top
				}, 500);
			}
			
		}
	});
}


function initListing() {
	$('.listing__summary button').on('click', function () {
		var $_listingItem = $(this).parents('.listing__item');
		
		// This item is already open so close it and do nothing else. Ever.
		if ($_listingItem.hasClass('expanded')) {
			$_listingItem.removeClass('expanded');
			$_listingItem.find('.listing__content').slideUp();
			$(this).text( $(this).data('open-text') );
			return;
		}
		
		
		var $_currentOpenItem = $('.listing__item.expanded');
		$_currentOpenItem.find('.listing__content').slideUp();
		
		
		$_listingItem.addClass('expanded');
		$_listingItem.find('.listing__content').slideDown();
		$(this).text( $(this).data('close-text') );
		
		setTimeout(function () {
			$('html, body').animate({
	            scrollTop: $_listingItem.offset().top - $('.header').outerHeight()
	        }, 500);
		}, 500);
	});

	var activeItems = $('.listing__item').filter(function() {
		return $(this).css('display') =='flex';
	})
	activeItems.filter(':odd').addClass('even');

	$('.listing__filters-controls button').on('click', function() {
		$('.even').each(function() {
			$(this).removeClass('even');
		})

		setTimeout(function() {
			var activeItems = $('.listing__item').filter(function() {
				return $(this).css('display') =='flex';
			})
			activeItems.filter(':odd').addClass('even');
		},500)
	})
}

function hideFiltersIfSingleCategory() {
	if ( $('.listing__filters-controls button').length <= 2 ) {
		$('.listing__filters').css('display', 'none');
	}
}

function initAccordion() {
	$('.accordion').accordiom({
		showFirstItem: false
	})
}